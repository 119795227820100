import Link from "next/link";
import {
  EmptyDiv,
  NavOverall,
  SideAnchor,
  SideButton,
  SideButtonDiv,
  SideSpan,
  SideTextDiv,
} from "./SideNavStyles";
import { BsFillBookmarkFill, BsFillPeopleFill, BsViewList } from "react-icons/bs";
import { MdRssFeed, MdSearch, MdSettings } from "react-icons/md";
import { RiBuildingLine } from "react-icons/ri";
import { useRouter } from "next/router";
import { useAuth } from "../../utils/auth/newAuth";
import { CgProfile } from "react-icons/cg";
import Image from "next/image";

const SideNav = () => {
  const router = useRouter();
  const profile = useAuth()?.profile;

  return (
    <NavOverall>
      <EmptyDiv />
      <nav>
        {profile && (
          <Link href="/" passHref prefetch={false}>
            <SideAnchor>
              <SideButton>
                <SideButtonDiv name="/" pathname={router.pathname} />
                <SideSpan>
                  <Image
                    src={
                      router.pathname === "/"
                        ? "/images/For_you_active.svg"
                        : "/images/For_you_inactive.svg"
                    }
                    width="24px"
                    height={"24px"}
                  />
                </SideSpan>
                <SideTextDiv>
                  <span
                    style={{
                      color: router.pathname === "/" ? "#125F3F" : "#5f6368",
                    }}
                  >
                    For you
                  </span>
                </SideTextDiv>
              </SideButton>
            </SideAnchor>
          </Link>
        )}
        <Link href="/listings" passHref prefetch={false}>
          <SideAnchor>
            <SideButton>
              <SideButtonDiv name="/listings" pathname={router.pathname} />
              <SideSpan>
                <BsViewList
                  color={
                    router.pathname === "/listings" ? "#125F3F" : "#5f6368"
                  }
                  size="24px"
                />
              </SideSpan>
              <SideTextDiv>
                <span
                  style={{
                    color:
                      router.pathname === "/listings" ? "#125F3F" : "#5f6368",
                  }}
                >
                  New listings
                </span>
              </SideTextDiv>
            </SideButton>
          </SideAnchor>
        </Link>

        <Link href="/active-wants" passHref prefetch={false}>
          <SideAnchor>
            <SideButton>
              <SideButtonDiv name="/active-wants" pathname={router.pathname} />
              <SideSpan>
                <MdRssFeed
                  size="24px"
                  color={
                    router.pathname === "/active-wants" ? "#125F3F" : "#5f6368"
                  }
                />
              </SideSpan>
              <SideTextDiv>
                <span
                  style={{
                    color:
                      router.pathname === "/active-wants"
                        ? "#125F3F"
                        : "#5f6368",
                  }}
                >
                  Active wants
                </span>
              </SideTextDiv>
            </SideButton>
          </SideAnchor>
        </Link>

        <Link href="/agents" passHref prefetch={false}>
          <SideAnchor>
            <SideButton>
              <SideButtonDiv name="/agents" pathname={router.pathname} />
              <SideSpan>
                <BsFillPeopleFill
                  size="24px"
                  color={
                    router.pathname === "/agents" ? "#125F3F" : "#5f6368"
                  }
                />
              </SideSpan>
              <SideTextDiv>
                <span
                  style={{
                    color:
                      router.pathname === "/agents"
                        ? "#125F3F"
                        : "#5f6368",
                  }}
                >
                  Find an Agent
                </span>
              </SideTextDiv>
            </SideButton>
          </SideAnchor>
        </Link>

        {profile &&
          <>
            <Link href="/my-listings" passHref prefetch={false}>
              <SideAnchor>
                <SideButton>
                  <SideButtonDiv name="/my-listings" pathname={router.pathname} />
                  <SideSpan>
                    <RiBuildingLine
                      size="24px"
                      color={
                        router.pathname === "/my-listings" ? "#125F3F" : "#5f6368"
                      }
                    />
                  </SideSpan>
                  <SideTextDiv>
                    <span
                      style={{
                        color:
                          router.pathname === "/my-listings"
                            ? "#125F3F"
                            : "#5f6368",
                      }}
                    >
                      My listings
                    </span>
                  </SideTextDiv>
                </SideButton>
              </SideAnchor>
            </Link>

            <Link href="/my-wants" passHref prefetch={false}>
              <SideAnchor>
                <SideButton>
                  <SideButtonDiv name="/my-wants" pathname={router.pathname} />
                  <SideSpan>
                    <MdSearch
                      size="24px"
                      color={
                        router.pathname === "/my-wants" ? "#125F3F" : "#5f6368"
                      }
                    />
                  </SideSpan>
                  <SideTextDiv>
                    <span
                      style={{
                        color:
                          router.pathname === "/my-wants" ? "#125F3F" : "#5f6368",
                      }}
                    >
                      My wants
                    </span>
                  </SideTextDiv>
                </SideButton>
              </SideAnchor>
            </Link>

            <Link href="/saved" passHref prefetch={false}>
              <SideAnchor>
                <SideButton>
                  <SideButtonDiv name="/saved" pathname={router.pathname} />
                  <SideSpan>
                    <BsFillBookmarkFill
                      color={router.pathname === "/saved" ? "#125F3F" : "#5f6368"}
                      size="24px"
                    />
                  </SideSpan>
                  <SideTextDiv>
                    <span
                      style={{
                        color:
                          router.pathname === "/saved" ? "#125F3F" : "#5f6368",
                      }}
                    >
                      Saved
                    </span>
                  </SideTextDiv>
                </SideButton>
              </SideAnchor>
            </Link>

            {profile?.slug && (
              <Link href={`/profile/${profile?.slug}`} prefetch={false}>
                <SideAnchor>
                  <SideButton>
                    <SideButtonDiv
                      name={`/profile/${profile?.slug}`}
                      pathname={router.pathname}
                    />
                    <SideSpan>
                      <CgProfile
                        size="24px"
                        color={
                          router.pathname === "/profile" ? "#125F3F" : "#5f6368"
                        }
                      />
                    </SideSpan>
                    <SideTextDiv>
                      <span
                        style={{
                          color:
                            router.pathname === `/profile/${profile?.slug}`
                              ? "#125F3F"
                              : "#5f6368",
                        }}
                      >
                        My profile
                      </span>
                    </SideTextDiv>
                  </SideButton>
                </SideAnchor>
              </Link>
            )}

            <Link href="/settings" passHref prefetch={false}>
              <SideAnchor>
                <SideButton>
                  <SideButtonDiv name="/settings" pathname={router.pathname} />
                  <SideSpan>
                    <MdSettings
                      size="24px"
                      color={
                        router.pathname === "/settings" ? "#125F3F" : "#5f6368"
                      }
                    />
                  </SideSpan>
                  <SideTextDiv>
                    <span
                      style={{
                        color:
                          router.pathname === "/settings" ? "#125F3F" : "#5f6368",
                      }}
                    >
                      Settings
                    </span>
                  </SideTextDiv>
                </SideButton>
              </SideAnchor>
            </Link>
          </>
        }
      </nav>
    </NavOverall>
  );
};

export default SideNav;
