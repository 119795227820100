import styled from 'styled-components';

export const NavOverall = styled.div`
	bottom: 0;
	left: 0;
	position: fixed;
	top: 0;
	width: 96px;
	z-index: 4;
    background: white;

    @media (max-width: 1023px) {
        display: none;
    }
`

export const EmptyDiv = styled.div`
    height: 64px;

`

export const SideAnchor = styled.a`
    color: #125F3F;
    text-decoration: none;
`

export const SideButton = styled.button`
	align-items: center;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	line-height: initial;
	min-height: 80px;
	padding: 8px;
	width: 100%;
    min-width: 96px;
    height: auto;
    position: relative;
    cursor: pointer;
    outline: none;
`

export const SideButtonDiv = styled.div`
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
left: 0;
width: 100%;
    z-index: 0;
    border-radius: 4px;
    background-color: ${props => props.name == props.pathname ? "#D2E1DB" : "#f1f3f4"};
    opacity: 0;
    transition: background-color 150ms linear;

    &:hover {
        opacity: 1;
    }

    &::after {
        position: absolute;
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
        content: "";
	    transition: opacity 150ms linear;
        z-index: 1;
        top: calc(50% - 100%);
        left: calc(50% - 100%);
        width: 200%;
        height: 200%;
    }
    &::before {
        transition: opacity 15ms linear,background-color 15ms linear;
        z-index: 1;
        opacity: 1;
    }
`

export const SideSpan = styled.span`
    color: #5f6368;
    margin-bottom: 14px;
    padding-top: 6px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 24px;
    padding: 8px;
    position: relative;
    text-align: center;
    pointer-events: none;

    // For the svg image for the "For you" page
    & > span {
        height: 24px !Important;
    }

    &:hover {
        cursor: pointer;
    }
`

export const SideTextDiv = styled.div`
    width: 100%;
    text-align: center;
    letter-spacing: 0.17px;
    font: 500 12px/16px 'Jarkarta', Roboto, Arial, sans-serif;
    z-index: 2;
        pointer-events: none;
`

